import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";

import Providers from "providers";
import LayoutWithHeaderAndFooter from "layout/LayoutWithHeaderAndFooter";
import NoMatch from "layout/navigation/NoMatch";

import IdleTimerPopup from "components/idle-timer-popup/IdleTimerPopup";
import ScrollToTop from "components/scroll-to-top/ScrollToTop";

import WelcomePage from "features/pages/welcome/WelcomePage";
import QuoteStart from "features/pages/quote/start/QuoteStart";
import QuoteResults from "features/pages/quote/results/QuoteResults";
import Addressee from "features/pages/app/addressee/Addressee";
import Beneficiaries from "features/pages/app/beneficiaries/Beneficiaries";
import Consent from "features/pages/app/consent/Consent";
import PaymentInfo from "features/pages/app/payment-info/PaymentInfo";
import PersonalInfo from "features/pages/app/personal-info/PersonalInfo";
import Review from "features/pages/app/review/Review";
import HealthStatement from "features/pages/app/health-statement/HealthStatement";
import WelcomeBack from "features/pages/welcome/WelcomeBack";
import CheckBack from "features/pages/check-back/CheckBack";
import OopsSorry from "features/pages/oops-sorry/OopsSorry";
import SessionExpired from "features/pages/session-expired/SessionExpired";

import Declined from "features/pages/results/declined/Declined";
import Approved from "features/pages/results/approved/Approved";
import Processing from "features/pages/app/processing/Processing";
import UnableToFinish from "features/pages/results/unable-to-finish/UnableToFinish";
import { InvalidParametersErrorPage } from "features/pages/invalid-parameters/InvalidParametersErrorPage";

const Router = () => {
    return (
        <BrowserRouter>
            <EverythingElse />
        </BrowserRouter>
    );
};

const EverythingElse = () => {
    const { pathname } = useLocation();
    return (
        <Providers pathname={pathname}>
            <ScrollToTop />
            <IdleTimerPopup />

            <Routes>
                <Route path="/" element={<LayoutWithHeaderAndFooter />}>
                    <Route index element={<WelcomePage />} />
                    <Route path="welcome-back" element={<WelcomeBack />} />
                    <Route path="check-back" element={<CheckBack />} />
                    <Route path="system-error" element={<OopsSorry />} />
                    <Route path="session-expired" element={<SessionExpired />} />
                    <Route path="invalid-parameters" element={<InvalidParametersErrorPage />} />

                    <Route path="quote" element={<QuoteStart />} />
                    <Route path="quote/results" element={<QuoteResults />} />

                    <Route path="app" component={null}>
                        <Route path="personal-info" element={<PersonalInfo />} />
                        <Route path="beneficiaries" element={<Beneficiaries />} />
                        <Route path="secondary-addressee" element={<Addressee />} />
                        <Route path="health-statement" element={<HealthStatement />} />
                        <Route path="payment-info" element={<PaymentInfo />} />
                        <Route path="review" element={<Review />} />
                        <Route path="consent" element={<Consent />} />
                        <Route path="processing" element={<Processing />} />
                    </Route>

                    <Route path="results" component={null}>
                        <Route path="approved" element={<Approved />} />
                        <Route path="declined" element={<Declined />} />
                        <Route path="unable-to-finish" element={<UnableToFinish />} />
                    </Route>

                    <Route path="*" element={<NoMatch />} />
                </Route>
            </Routes>
        </Providers>
    );
};

export default Router;
