import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { SplitColumn, PageDetails, InfoPanel, ButtonGroup } from "layout";
import { H1, H2 } from "components/text";
import { Button } from "components/inputs";
import InvitationCodeForm, { OnSubmitArgs } from "features/forms/invitation-code-form/InvitationCodeForm";
import { useFormState, usePageTitle, useSendInteraction, useLandingPageUrl } from "hooks";
import { setRawHtml } from "content/setRawHtml";
import { DTOPageEnum } from "constants/pageNames";
import { useContent } from "hooks/useContent";
import { TaskFallback } from "components/TaskFallback";
import { WelcomeContentSchema } from "content/contentSchemas";
import Loader from "components/loader/Loader";
import { toLowerCaseGenderFromSingleLetter } from "utils/conversions";

const HorizontalRule = styled.hr`
    margin-top: 2em;
    margin-bottom: 1em;
`;

const pageName = DTOPageEnum.enum.welcome;

function pipedOffersToArray(offers: string) {
    return offers.split("|");
}

const WelcomePage = () => {
    usePageTitle("Welcome | AAA Life");
    useSendInteraction(pageName);

    const { state, dispatch } = useFormState(pageName);

    const { result: content, ...contentStatus } = useContent({
        targetSchema: WelcomeContentSchema,
        applicationMode: state.applicationMode,
        pageName: "welcome",
        clubCode: state.clubSpecificData?.clubCode ?? state.campaign?.clubCode ?? undefined,
    });

    const navigate = useNavigate();

    useEffect(() => {
        if ((state.parameterErrors?.length ?? 0) > 0) {
            console.error(state.parameterErrors);
            // TBD: Set this page path or experience here depending on EOPS-947
            navigate("/invalid-parameters");
        } else {
            // FUTURE: Start findOffer here automatically here if provided uid
            // value appears to be a generic one or perhaps if indicated through
            // a different parameter, it is also possible that this
            // implementation is moved and the user goes directly to /quote.
            // See EOPS-842
        }
    }, [navigate, state.parameterErrors]);

    const landingPageParameters = useLandingPageUrl();
    useEffect(() => {
        dispatch({
            type: "LANDING_PAGE_VISITED",
            ...{ url: landingPageParameters.url, params: landingPageParameters.params },
        });
    }, [dispatch, landingPageParameters]);

    const handleClick = async ({ invitationCode, offer }: OnSubmitArgs) => {
        dispatch({
            type: "OFFER_FOUND",
            invitationCode: offer.finderNumber ?? invitationCode,
            firstName: offer.firstName ?? "",
            lastName: offer.lastName ?? "",
            planCode: offer.planCode ?? "",
            offer: pipedOffersToArray(offer.offer ?? ""),
            keyCode: offer.keycode ?? "",
            aaaMemberNumber: offer.aaaMemberNumber ?? "",
            memberOfferAvailable: offer.memberOfferAvailable,
            spouseOfferAvailable: offer.spouseOfferAvailable,
            membershipLength: offer.memberLoyaltyYears,
            memberSince: offer.memberJoinDate ?? undefined,
            gender: toLowerCaseGenderFromSingleLetter(offer.gender) ?? undefined,
        });

        if (!offer.memberOfferAvailable && !offer.spouseOfferAvailable) {
            // No offers are available
            navigate("/welcome-back");
            return;
        }

        if (state.applicationMode === "loyalty" && !("memberLoyaltyYears" in offer)) {
            console.error("Required offer properties were not provided for loyalty offer.");
            navigate("/system-error");
            return;
        }

        navigate("/quote");
    };

    return (
        <SplitColumn>
            {content && (
                <TaskFallback fallback={<Loader />} errorFallback={<div>ERROR LOADING CONTENT</div>} {...contentStatus}>
                    <InfoPanel>
                        <H1>{content.leftPanelTitle}</H1>
                        <section {...setRawHtml(content.leftPanelBodyHtml)} />
                        {content.leftPanelFooterHtml && (
                            <>
                                <HorizontalRule />
                                <footer {...setRawHtml(content.leftPanelFooterHtml)} />
                            </>
                        )}
                    </InfoPanel>
                    <PageDetails>
                        <H2>{content.pageTitle}</H2>
                        <InvitationCodeForm
                            content={content}
                            onSubmit={handleClick}
                            invitationCode={state.invitationCode ?? undefined}
                        />
                        <ButtonGroup>
                            <Button type="submit" label={content.nextLabel} color="primary" form="invitation-code-form" />
                        </ButtonGroup>
                    </PageDetails>
                </TaskFallback>
            )}
        </SplitColumn>
    );
};

export default WelcomePage;
