import { z } from "zod";
import { DTOPageEnum, DTOPages } from "constants/pageNames";
import { ApplicationModeEnum } from "state/ApplicationStateSchema";

export const interactionDefaultsSchema = z.object({
    interactionCode: z.string().regex(/^[A-Z]{2}\d{2}$/),
    dispositionCode: z.string().regex(/^[A-Z_]*$/),
    isFinalInteraction: z.boolean(),
    interactionLevel: z.number().optional(),
});

const DTOPageInteractionDefaultsListSchema = z.record(DTOPageEnum, interactionDefaultsSchema);

type DTOPageInteractionDefaults = z.infer<typeof DTOPageInteractionDefaultsListSchema>;

export const DTOPageInteractionDefaultsList: DTOPageInteractionDefaults = {
    [DTOPageEnum.enum.welcome]: {
        interactionCode: "DT01",
        dispositionCode: "WELCOME",
        isFinalInteraction: false,
    },
    [DTOPageEnum.enum.quoteStart]: {
        interactionCode: "DT02",
        dispositionCode: "QUOTE_START",
        isFinalInteraction: false,
    },
    [DTOPageEnum.enum.quoteResults]: {
        interactionCode: "DT03",
        dispositionCode: "QUOTE_RESULTS",
        isFinalInteraction: false,
    },
    [DTOPageEnum.enum.personalInfo]: {
        interactionCode: "DT04",
        dispositionCode: "APPLICANT_INFO",
        isFinalInteraction: false,
    },
    [DTOPageEnum.enum.beneficiary]: {
        interactionCode: "DT05",
        dispositionCode: "BENEFICIARY",
        isFinalInteraction: false,
    },
    [DTOPageEnum.enum.secondaryAddressee]: {
        interactionCode: "DT06",
        dispositionCode: "SECOND_ADDRESSEE",
        isFinalInteraction: false,
    },
    [DTOPageEnum.enum.healthStatement]: {
        interactionCode: "DT07",
        dispositionCode: "STMT_OF_HEALTH",
        isFinalInteraction: false,
    },
    [DTOPageEnum.enum.paymentInfo]: {
        interactionCode: "DT08",
        dispositionCode: "PAYMENT",
        isFinalInteraction: false,
    },
    [DTOPageEnum.enum.review]: {
        interactionCode: "DT09",
        dispositionCode: "REVIEW_APP",
        isFinalInteraction: false,
    },
    [DTOPageEnum.enum.consent]: {
        interactionCode: "DT10",
        dispositionCode: "APP_CONSENT",
        isFinalInteraction: false,
    },
    [DTOPageEnum.enum.processing]: {
        interactionCode: "DT11",
        dispositionCode: "APP_SBMT_PROCESSING",
        isFinalInteraction: false,
    },
    [DTOPageEnum.enum.approved]: {
        interactionCode: "DT19",
        dispositionCode: "CONGRATS_PAGE",
        isFinalInteraction: true,
    },
    [DTOPageEnum.enum.declined]: {
        interactionCode: "DT13",
        dispositionCode: "DECLINED",
        isFinalInteraction: true,
    },
    [DTOPageEnum.enum.unableToFinish]: {
        interactionCode: "DT14",
        dispositionCode: "UNBL_FINISH",
        isFinalInteraction: true,
    },
    [DTOPageEnum.enum.oopsSorry]: {
        interactionCode: "DT15",
        interactionLevel: 0,
        dispositionCode: "ERROR",
        isFinalInteraction: false,
    },
    [DTOPageEnum.enum.sessionExpired]: {
        interactionCode: "DT16",
        interactionLevel: 0,
        dispositionCode: "SESSION_EXP",
        isFinalInteraction: true,
    },
    [DTOPageEnum.enum.checkBack]: {
        interactionCode: "DT17",
        dispositionCode: "CHECK_BACK",
        isFinalInteraction: false,
    },
    [DTOPageEnum.enum.welcomeBack]: {
        interactionCode: "DT18",
        dispositionCode: "WELCOME_BACK",
        isFinalInteraction: false,
    },
    [DTOPageEnum.enum["invalid-parameters"]]: {
        // TBD: Change these values once they are decided for this new page
        interactionCode: "XXXX",
        dispositionCode: "INV_PARAM",
        isFinalInteraction: false,
    },
};

export const LoyaltyPageInteractionDefaults: DTOPageInteractionDefaults = {
    [DTOPageEnum.enum.welcome]: {
        interactionCode: "ML01",
        dispositionCode: "WELCOME",
        isFinalInteraction: false,
    },
    [DTOPageEnum.enum.quoteStart]: {
        interactionCode: "ML02",
        dispositionCode: "QUOTE_START",
        isFinalInteraction: false,
    },
    [DTOPageEnum.enum.quoteResults]: {
        interactionCode: "ML03",
        dispositionCode: "QUOTE_RESULTS",
        isFinalInteraction: false,
    },
    [DTOPageEnum.enum.personalInfo]: {
        interactionCode: "ML04",
        dispositionCode: "APPLICANT_INFO",
        isFinalInteraction: false,
    },
    [DTOPageEnum.enum.beneficiary]: {
        interactionCode: "ML05",
        dispositionCode: "BENEFICIARY",
        isFinalInteraction: false,
    },
    [DTOPageEnum.enum.secondaryAddressee]: {
        interactionCode: "ML06",
        dispositionCode: "SECOND_ADDRESSEE",
        isFinalInteraction: false,
    },
    [DTOPageEnum.enum.paymentInfo]: {
        interactionCode: "ML08",
        dispositionCode: "PAYMENT",
        isFinalInteraction: false,
    },
    [DTOPageEnum.enum.review]: {
        interactionCode: "ML09",
        dispositionCode: "REVIEW_APP",
        isFinalInteraction: false,
    },
    [DTOPageEnum.enum.consent]: {
        interactionCode: "ML10",
        dispositionCode: "APP_CONSENT",
        isFinalInteraction: false,
    },
    [DTOPageEnum.enum.processing]: {
        interactionCode: "ML11",
        dispositionCode: "APP_SBMT_PROCESSING",
        isFinalInteraction: false,
    },
    [DTOPageEnum.enum.approved]: {
        interactionCode: "ML20",
        dispositionCode: "CONGRATS_PAGE",
        isFinalInteraction: true,
    },
    [DTOPageEnum.enum.declined]: {
        interactionCode: "ML13",
        dispositionCode: "DECLINED",
        isFinalInteraction: true,
    },
    [DTOPageEnum.enum.unableToFinish]: {
        interactionCode: "ML14",
        dispositionCode: "UNBL_FINISH",
        isFinalInteraction: true,
    },
    [DTOPageEnum.enum.oopsSorry]: {
        interactionCode: "ML15",
        interactionLevel: 0,
        dispositionCode: "ERROR",
        isFinalInteraction: false,
    },
    [DTOPageEnum.enum.sessionExpired]: {
        interactionCode: "ML16",
        interactionLevel: 0,
        dispositionCode: "SESSION_EXP",
        isFinalInteraction: true,
    },
    [DTOPageEnum.enum.checkBack]: {
        interactionCode: "ML17",
        dispositionCode: "CHECK_BACK",
        isFinalInteraction: false,
    },
    [DTOPageEnum.enum.welcomeBack]: {
        interactionCode: "ML18",
        dispositionCode: "WELCOME_BACK",
        isFinalInteraction: false,
    },
    [DTOPageEnum.enum["invalid-parameters"]]: {
        // TBD: Change these values once they are decided for this new page
        interactionCode: "XXXX",
        dispositionCode: "INV_PARAM",
        isFinalInteraction: false,
    },
};

export function getPageInteractionDefaults(applicationMode: ApplicationModeEnum, pageName: DTOPages) {
    return applicationMode === "directterm"
        ? DTOPageInteractionDefaultsList[pageName]
        : LoyaltyPageInteractionDefaults[pageName];
}
