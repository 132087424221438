import { AnyZodObject, z } from "zod";
import { GendersSchema } from "types/common/Genders";

export const HeightSchema = z
    .object({
        feet: z.number().int(),
        inches: z.number().int(),
    })
    .nullish();

const PolicyToReplaceSchema = z.object({
    company: z.string(),
    policyNumber: z.string().nullish(),
});

export const CampaignSchema = z.object({
    clubCode: z.string().nullish(),
    leadSource: z.string().nullish(),
    campaignCode: z.string().nullish(),
    campaignUrl: z.string().nullish(),
});

export const QuoteOptionSchema = z.object({
    coverageAmount: z.number(),
    monthlyPremium: z.number(),
});
export type QuoteOption = z.infer<typeof QuoteOptionSchema>;

export const OfferStringSchema = z.string().regex(/^[a-zA-Z0-9]+$/gi);
export const LoyaltyTierSchema = OfferStringSchema;
export type LoyaltyTier = z.infer<typeof LoyaltyTierSchema>;

export const CoverageTypesSchema = z.enum(["Family", "Individual"]);
export type CoverageTypes = z.infer<typeof CoverageTypesSchema>;

export const BenefitOptionSchema = z.object({
    tier: OfferStringSchema,
    airline: z.number(),
    common: z.number(),
    lossOfLife: z.number(),
    hospital: z.number(),
    emergencyRoom: z.number(),
    allAccident: z.number(),
    individual: z.number(),
    family: z.number(),
});
export type BenefitOption = z.infer<typeof BenefitOptionSchema>;

export const BeneficiarySchema = z.object({
    firstName: z.string(),
    middleInitial: z.string().nullish(),
    lastName: z.string(),
    percentage: z.number().int(),
    relationship: z.string(),
});

export const StrictSecondaryAddresseeSchema = z.object({
    // These properties are nullish because they may be partially filled if designatedSecondaryAddressee is false
    firstName: z.string(),
    middleInitial: z.string().optional(),
    lastName: z.string(),
    phone: z.string().optional(),
    addressLine1: z.string(),
    addressLine2: z.string().optional(),
    city: z.string(),
    state: z.string(),
    zipCode: z.string(),
});

export const SecondaryAddresseeSchema = StrictSecondaryAddresseeSchema.deepPartial();

export const PolicyStatusSchema = z.enum(["approved", "alreadyused", "declined", "refertounderwriter"]);

export const ApplicantTypeEnum = z.enum(["member", "spouse"]);
const QuotePropertiesSchema = z.object({
    applicantType: ApplicantTypeEnum,
    gender: z.string().optional(),
    dateOfBirth: z.string(),
    zipCode: z.string(),
    state: z.string(),
    email: z.string().optional(),
    hasUsedNicotineLastYear: z.boolean(),
});

export const SelectedCoverageSchema = z.object({
    selectedCoverageAmount: z.number().nullish(),
    selectedCoverageTier: z.string().nullish(),
    selectedCoverageType: CoverageTypesSchema.nullish(),
    selectedCoveragePremium: z.number().nullish(),
    coverageAmounts: z.array(z.number()).nullish(),
});

export const PhoneTypesEnum = z.enum(["Home", "Mobile", "Business"]);

export const PersonalInfoSchema = z.object({
    policyNumber: z.string().nullish(),
    hannoverId: z.string(),
    addressLine1: z.string(),
    addressLine2: z.string().nullish(),
    city: z.string(),
    state: z.string(),
    phone: z.string(),
    phoneType: PhoneTypesEnum,
    email: z.string(),
    height: HeightSchema.nullish(),
    weight: z.number().int().nullish(),
    willReplacePolicy: z.boolean().nullish(),
    policyToReplace: PolicyToReplaceSchema.partial().nullish(),
});

export const HealthQuestionsSchema = z.object({
    hasSevereMedicalConditions: z.boolean(),
    hadDiagnosticTesting: z.boolean(),
});

export const ApplicationSchema = z
    .object({
        applicationID: z.string(),
        aaaMemberNumber: z.string().nullish(),
        keyCode: z.string(),
        firstName: z.string(),
        middleInitial: z.string().nullish(),
        lastName: z.string(),

        clubCode: z.string(),

        designatedSecondaryAddressee: z.boolean(),
        secondaryAddressee: SecondaryAddresseeSchema.nullish(),

        beneficiary: BeneficiarySchema,

        paymentFrequency: z.enum(["Monthly"]),

        paymentToken: z.string(),
        agreeToConsent: z.boolean(),
        policyStatus: PolicyStatusSchema.nullish(),
    })
    .merge(QuotePropertiesSchema)
    .merge(SelectedCoverageSchema)
    .merge(PersonalInfoSchema)
    .merge(HealthQuestionsSchema);

export const DirectTermOfferSchema = OfferStringSchema;
export type DirectTermOfferEnum = z.infer<typeof DirectTermOfferSchema>;

export const OfferStateSchema = z.object({
    invitationCode: z.string().nullish(),
    planCode: z.string().nullish(),
    firstName: z.string().nullish(),
    middleInitial: z.string().nullish(),
    lastName: z.string().nullish(),
    keyCode: z.string().nullish(),
    aaaMemberNumber: z.string().nullish(),
    memberOfferAvailable: z.boolean().nullish(),
    spouseOfferAvailable: z.boolean().nullish(),
    membershipLength: z.number().optional(),
    memberSince: z.string().optional(),
    gender: GendersSchema.optional(),
});

export const ApplicationModeEnumSchema = z.enum(["directterm", "loyalty"]);
export type ApplicationModeEnum = z.infer<typeof ApplicationModeEnumSchema>;

export const ApplicationStateSchema = z
    .object({
        applicationMode: ApplicationModeEnumSchema,

        application: ApplicationSchema.partial().nullish(),
        campaign: CampaignSchema.optional(),

        clubSpecificData: z
            .object({
                /** The club code determined via ZIP code */
                clubCode: z.string(),
                /** The plan code determined via ZIP code */
                planCode: z.string(),
            })
            .optional(),

        session: z.object({
            sessionID: z.string(),
            timeout: z.number().nullish(),
            promptBeforeIdle: z.number().nullish(),
        }),
        parameterErrors: z.string().array().optional(),
    })
    .merge(OfferStateSchema);

export type Application = z.infer<typeof ApplicationSchema>;
export type ApplicationState = z.infer<typeof ApplicationStateSchema>;

export const LoyaltyApplicationStateSchema = z.object({
    ...ApplicationStateSchema.shape,
    applicationMode: z.literal(ApplicationModeEnumSchema.Enum.loyalty),
    offer: LoyaltyTierSchema.array().nullish(),
    membershipLevel: z.number().optional(),
    application: z
        .object({ ...ApplicationSchema.shape, coverageOptions: BenefitOptionSchema.array() })
        .partial()
        .nullish(),
});
export type LoyaltyApplicationState = z.infer<typeof LoyaltyApplicationStateSchema>;

export const DirectTermApplicationStateSchema = z.object({
    ...ApplicationStateSchema.shape,
    applicationMode: z.literal(ApplicationModeEnumSchema.Enum.directterm),
    offer: DirectTermOfferSchema.array().nullish(),
    application: z
        .object({ ...ApplicationSchema.shape, coverageOptions: QuoteOptionSchema.array() })
        .partial()
        .nullish(),
});
export type DirectTermApplicationState = z.infer<typeof DirectTermApplicationStateSchema>;

export const ApplicationStatesSchema = z.discriminatedUnion("applicationMode", [
    LoyaltyApplicationStateSchema,
    DirectTermApplicationStateSchema,
]);
export type ApplicationStates = z.infer<typeof ApplicationStatesSchema>;

/**
 * A function that creates a dynamic schema based on the specified applicationStepSchema which wraps that schema within
 * an object that has two potential applications of which one is active in a way designated by the `applicantType` property.
 */
export function makeBaseApplicationSchema<TApplicationSchema extends AnyZodObject, TAdditionalRootSchema extends AnyZodObject>(
    applicationStepSchema: TApplicationSchema,
    additionalRootSchema: TAdditionalRootSchema
) {
    return z
        .object({
            application: applicationStepSchema,
        })
        .merge(additionalRootSchema);
}
